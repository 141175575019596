<template>
  <div class="ecology">
    <div class="head">
      <HeaderView :bgColor="bgColor"></HeaderView>
    </div>
    <div class="banner">
      <div class="banner_text">
          <h1>平台生态合作</h1>
          <p>携手伙伴共建数字化协作通路,助力产业数字化创建升级</p>
      </div>
    </div>
    <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
      数字供应链服务平台的顶层设计
      <div class="line"></div>
    </div>
    <div class="imgBox wow animate__fadeInUp" data-wow-duration="2s">
      <img src="../assets/images/ecology/szgyl.png" alt="">
    </div>
    <div class="digitalizeBox">
      <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
        数字化钢铁工业产业链生态圈
        <div class="line"></div>
      </div>
      <ul class="digitalize_content wow animate__fadeInUp" data-wow-duration="2s">
        <li>
          <h1>
            <div class="imgdiv"><img src="../assets/images/ecology/hyxx_icon.png" alt=""/></div><span>行业信息共享</span>
          </h1>
          <p>同步全球钢贸信息,实现生态圈内业务指导信息、检测商家信息、商品检测信息、消费者产品共享,协同搞笑管理,建立多商家档案,共享建立商品报价信息、电商网站平台信息...</p>
        </li>
        <li>
          <h1>
            <div class="imgdiv"><img src="../assets/images/ecology/szh_icon.png" alt=""></div><span>数字化整合</span>
          </h1>
          <p>实现圈内业务流、资金流、数据流等多流信息统一,即:一个平台实现点端资源统一调配,高度共享业财一体化信息,同价目录。平台设立统一接口,集中业务指导,实行数字化管理。</p>
        </li>
        <li>
          <h1>
            <div class="imgdiv"><img src="../assets/images/ecology/zygx_icon.png" alt=""></div><span>资源共享</span>
          </h1>
          <p>实现公共仓、钢厂库、货主私人库圈内资源共享,利用六六仓平台快速结算优势,达到资金周转最快化、最大化.资源信息共享使每一笔钢贸业务都能做到一站式服务的能力。</p>
        </li>
        <li>
          <h1>
            <div class="imgdiv"><img src="../assets/images/ecology/jrfn_icon.png" alt=""></div><span>金融赋能</span>
          </h1>
          <p>通过上下游资源共享整合,在六六仓体系内,实现生态圈内金融背书能力,让圈内企业资金使用快速便捷,解决圈内企业经营发展中的资金占压和短缺困境。</p>
        </li>
      </ul>
    </div>
    <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
      合作运营模式
      <div class="line"></div>
    </div>
    <div class="cooperate_box wow animate__fadeInUp" data-wow-duration="2s">
      <ul class="cooperate">
      <li  v-for='(item,index) in cooperateData' :key='index' @click="changeNum(index)">
        <h1><img :src="item.url" alt=""></h1>
        <div class="cooperate_img">
        </div>
        <p>{{item.content}}</p>
      </li>
      <!-- <li>
        <h1><img src="../assets/images/ecology/hzgs.png" alt=""></h1>
        <div class="cooperate_img"></div>
        <p>成立合资公司,六六仓储作为股东指导园区的体系落地与运营,接入生态圈实现共赢。</p>
      </li>
      <li>
        <h1><img src="../assets/images/ecology/jsfn.png" alt=""></h1>
        <div class="cooperate_img"></div>
        <p>六六仓出作为技术服务商,输出完整的技术解决方案,完成园区的升级改造,可接入生态圈实现共赢发展。</p>
      </li> -->
      </ul>
    </div>
  </div>
</template>
<script>
import HeaderView from '@/components/headerView.vue'
export default {
  name:'ecology',
  data(){
    return{
      bgColor:'rgba(255,255,255,0)',
      cooperateData:[
        {url:require('../assets/images/ecology/dlyy.png'),content:'六六仓储作为运营服务商,承接园区的运营服务弯沉园区的体系落地与运营,可接入生态圈。'},
        {url:require('../assets/images/ecology/hzgs.png'),content:'成立合资公司,六六仓储作为股东指导园区的体系落地与运营,接入生态圈实现共赢。'},
        {url:require('../assets/images/ecology/jsfn.png'),content:'六六仓出作为技术服务商,输出完整的技术解决方案,完成园区的升级改造,可接入生态圈实现共赢发展。'},
      ],
      num:0,
    }
  },
  mounted(){
    new this.$wow.WOW().init({//新建实列
      boxClass: 'wow',//class名字
      // animateClass: 'animate__animated',//animateclass动画库类名,和当前animate动画库决定
      offset: 0,//到元素距离触发动画（当默认为0）
      mobile: true,//在移动设备上触发动画（默认为true）
      live: true//对异步加载的内容进行操作（默认为true）
    })
  },
  components: {
    HeaderView
  },
  methods:{
    changeNum(index){
      this.num = index
    }
  }
}
</script>
<style scoped lang="less">
.banner{
  width:1920px;
  height:471px;
  position: relative;
  background: url('../assets/images/ecology/banner.png')no-repeat;
  background-size:100% 100%;
  .banner_text{
    width:1200px;
    margin:0 auto;
    padding-top:170px;
    padding-left:80px;
    text-align:left;
    color: #333333;
    animation: banner-animation 1s ease-in-out;
  }
  h1{
    height: 40px;
    font-size: 40px;
    margin-bottom:30px;
  }
  p{
    font-size: 20px;
  }
}
.module_title{
  width:1920px;
  text-align:center;
}
.imgBox{
  width:1920px;
  text-align:center;
  img{
    width:1200px;
  }
}
.digitalizeBox{
  width:1920px;
  height: 751px;
  background: #F3F3F3;
  padding-top:10px;
  margin-top:30px;
}
// 数字化钢铁工业产业链生态圈
.digitalize_content{
  width:1200px;
  margin:0 auto;
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  li{
    width:520px;
    height:200px;
    box-sizing: border-box;
    padding:40px 65px;
    margin:0 20px;
    margin-bottom:50px;
    color:#fff;
    h1{
      display:flex;
      position:relative;
      font-size:20px;
      margin-bottom:10px;
      .imgdiv{
        width:25px;
        height:25px;
        line-height:25px;
        text-align:center;
        border-radius:50%;
        background:#fff;
        position:absolute;
        left:-35px;
        top:4px;
      }
    }
    p{
      font-size:15px;
      line-height:24px;
    }
    &:nth-of-type(1){
      background:url('../assets/images/ecology/hyxx_bg.png')no-repeat;
      background-size:100% 100%;
    }
    &:nth-of-type(2){
      background:url('../assets/images/ecology/szh_bg.png')no-repeat;
      background-size:100% 100%;
    }
    &:nth-of-type(3){
      background:url('../assets/images/ecology/zygx_bg.png')no-repeat;
      background-size:100% 100%;
    }
    &:nth-of-type(4){
      background:url('../assets/images/ecology/jrfn_bg.png')no-repeat;
      background-size:100% 100%;
    }
  }
}
// 合作运营模式
.cooperate_box{
  width:1920px;
}
.cooperate{
  width:1200px;
  display:flex;
  justify-content: space-around;
  margin:0 auto;
  padding:50px;
  box-sizing:border-box;
  margin-bottom:40px;
  li{
    width: 317px;
    height: 389px;
    opacity: 1;
    border: 1px solid #9F9F9F;
    box-sizing:border-box;
    padding:60px 50px;
    padding-bottom:0;
    display:flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
    .cooperate_img{
      width:58px;
      height:60px;
      margin-bottom:35px;
      background:url('../assets/images/ecology/green_jian.png')no-repeat center;
    }
    img{
      margin-bottom:35px;
    }
    p{
      line-height:30px;
    }
    &:hover{
      background:#5384DF;
      .cooperate_img{
        background:url('../assets/images/ecology/blue_jian.png')no-repeat center;
      }
      color:#fff;
    }
  }
}
</style>
